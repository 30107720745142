import { atom, useRecoilValue } from "recoil";

export const playlistState = atom({
  key: "playlistState",
  default: {},
});

export const GetPlaylistFromRecoil = () => {
  const recoilPlaylist = useRecoilValue(playlistState);
  return recoilPlaylist;
};

export const videoIdState = atom({
  key: "videoIdState",
  default: null,
});

export const GetVideoIdFromRecoil = () => {
  const recoilVideoId = useRecoilValue(videoIdState);
  return recoilVideoId;
};

export const videoTypeState = atom({
  key: "videoTypeState",
  default: null,
});

export const GetVideoTypeFromRecoil = () => {
  const recoilVideoType = useRecoilValue(videoTypeState);
  return recoilVideoType;
};

export const videoToPlayState = atom({
  key: "videoToPlayState",
  default: null,
});

export const GetVideoToPlayFromRecoil = () => {
  const recoilVideoToPlay = useRecoilValue(videoToPlayState);
  return recoilVideoToPlay;
};

export const liveIdToUpdateState = atom({
  key: "liveIdToUpdateState",
  default: null,
});

export const GetLiveIdToUpdateFromRecoil = () => {
  const recoilLiveIdToUpdateState = useRecoilValue(liveIdToUpdateState);
  return recoilLiveIdToUpdateState;
};
