import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_MEDIA_DATA = gql`
  query getMediaData($mediaId: ID!) {
    media(id: $mediaId) {
      id
      title
      selectedThumb
      selectedThumbCdnUrl
      sourceUrl
      isEncodingDone
      type
      playlist
      playlistCdnUrl
      isPublished
      disableAnalytic
      encodingVersion
      encodingProgress {
        id
        isEncodingDone
        encodingVersion
      }
      confidentiality {
        stringValue
      }
      subtitles {
        id
        languageCode
        url
      }
      discussion {
        id
        preset
      }
      publisher {
        id
        cdnEndpoint
      }
      live {
        id
        waitingThumb
        liveUrl
        hasReplay
        poll {
          id
        }
      }
      chapters {
        id
      }
      files {
        id
        url
        isActive
        type
      }
      publisher {
        id
        preferences {
          id
          pageSetting {
            watermarkEnabled
            watermarkPosition
            watermarkLink
            watermarkImage
          }
          watermarkEnabled
          watermarkPosition
          watermarkLink
          watermarkImage
        }
      }
      pageSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
        restrictedDomains{id,domain}
      }
      embedSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
        restrictedDomains{id,domain}
        privacyType
      }
    }
  }
`;
